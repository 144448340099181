.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.contaner
{
  margin: 0;
  font-family: Inter, sans-serif;
  height: 100vh;
  background-color: #282D35;
  max-width: 100%;
  width: 80%;
}

.App-Row {
  margin: auto;
  width: 100%;
  height: 30%;
  display: inline-block;
  vertical-align: top;
}

.App-Column
{
  vertical-align: top;
  width: 50%;
  display: inline-block;
  margin: auto;
}

* {
    box-sizing: border-box;
}


nav {
    display: flex;
    align-items: center;
    background-color: #21222A;
    height: 70px;
    padding: 10px 20px;
}

.nav--logo_text, .nav--title {
    margin-left: 10;
}

.nav--logo_text {
    margin-right: auto;
    color: white;
    font-weight: 700;
    font-size: 22px;
}

.nav--logo_text_sub {
    margin-right: auto;
    color: white;
    font-weight: 600;
    font-size: 15px;
    opacity: 0.7;
}

.nav--title {
    color: #DEEBF8;
    font-weight: 600;
}


body {
  background-color: #282D35;
  color: #f4f4f4;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100px;
}

.signInButton {
  color:red;
  float:none;
  position:static;
  display:block;
  margin:auto;
  width:max-content;
}
